import React from 'react';
import RoomIcon from '@material-ui/icons/Room';
import GoogleMapReact from 'google-map-react';



const Marker: React.FC<{ text: string; [x: string]: any }> = ({ text }) => (
    <>
  <RoomIcon
    style={{
      color:"#F4CC3B",
      fontSize:" -webkit-xxx-large"
    }}
    width="100px"
    height="100px"
  />
    


  </>
);


interface MapProps {
  [x: string]: any;
}

const GoogleMap: React.FC<MapProps> = ({
  center = {
    lat: 29.736325, 
    lng:  -95.507608,
  },
  
  zoom = 16,
  
}) => {
  return (
    <div>
      <div style={{ height: '500px', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyDA-_cBTB9jkPqqlaJX_1xZjl7zhH_3adw' }}
          defaultCenter={center}
          defaultZoom={zoom}
        >
          <Marker lat={center.lat} lng={center.lng} text="My Marker"  />
          <Marker lat={center.lat+1.72747} lng={center.lng+3.825667} text="My Marker2"  />
       
        </GoogleMapReact>
      </div>
    </div>
  );
};

export default GoogleMap;
