import * as React from "react"
import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"
import Text from "../components/text/text"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { useTheme } from "@material-ui/core"
import GoogleMap from "../components/GoogleMap/googleMap"
import PhoneIcon from "@material-ui/icons/Phone"

const Locations = () => {
  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <div style={{ backgroundColor: "white", color: "white" }}>
      <Container maxWidth="lg">
        <Grid container>
          <Grid item lg={12} xl={12} style={{ paddingTop: "130px" }}>
            <Text variant={"h3"} style={{ fontWeight: "bold" }}>
              Locations
            </Text>
            <hr
              style={{
                color: "",
                height: "1px  ",
                paddingTop: "2px",
                borderWidth: "1px",
                backgroundColor: "#A72D3F",
                marginBottom: "30px",
                marginTop: "15px",
                width: smDown ? "35ch" : "",
              }}
            />{" "}
          </Grid>
        </Grid>
        <Grid item lg={12} xl={12}>
          <GoogleMap />
        </Grid>
        <Grid container>
          <Grid
            item
            lg={6}
            xl={6}
            style={{ paddingTop: "20px", paddingBottom: "40px" }}
          >
            <Text
              variant={"h4"}
              style={{ fontWeight: "bold", paddingLeft: "10px" }}
            >
              7919 Westheimer Road
            </Text>
            {/* <Text
              variant={"body1"}
              style={{ fontWeight: "bold", paddingLeft: "10px" }}
            ></Text> */}
            <Text
              variant={"body1"}
              style={{ fontWeight: "bold", paddingLeft: "10px" }}
            >
              <Text
                variant={"body1"}
                style={{ fontWeight: "bold", paddingTop: "20px" }}
              >
                <PhoneIcon /> (281) 740-1898
              </Text>
            </Text>
          </Grid>
          <Grid item lg={6} xl={6} style={{ paddingTop: "20px" }}>
            {/* <Text
              variant={"h4"}
              style={{ fontWeight: "bold", paddingLeft: "10px" }}
            ></Text> */}
            <Text
              variant={"body1"}
              style={{ fontWeight: "bold", paddingLeft: "10px" }}
            >
              Houston, TX ,77063
            </Text>
            <Text
              variant={"body1"}
              style={{
                fontWeight: "bold",
                paddingLeft: "10px",
                paddingTop: "20px",
              }}
            >
              <PhoneIcon /> (281) 740-1898
            </Text>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default Locations
